/* You can add global styles to this file, and also import other style files */
@use '~@angular/material' as mat;

@import '@grapecity/wijmo.styles/wijmo.css';
@import '~@angular/material/prebuilt-themes/indigo-pink.css';
@import '~@angular/material/theming';
// @import url('https://fonts.googleapis.com/css?family=Roboto&display=swap');
@import url('https://fonts.googleapis.com/css?family=Nunito:wght@300;400;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400&display=swap');
@import url('https://fonts.googleapis.com/css?family=Material+Icons|Material+Icons+Round|Material+Icons+Sharp|Material+Icons+Outlined|Material+Symbols+Outlined');
@import '~jsoneditor/dist/jsoneditor.min.css';
@import '~angular2-draggable/css/resizable.min.css';
@import '../src/assets/styles/devices.scss';

@font-face {
  font-family: 'Tahoma';
  src: url('../src/assets/fonts/Tahoma Regular font.ttf');
}

$system-font: 'Nunito', sans-serif;

$custom-typography: mat.define-typography-config(
        $font-family: $system-font,
);
@include mat.core($custom-typography);
$custom-primary: mat.define-palette(mat.$deep-purple-palette);

body,
html {
  color: #4F596A;
  font-family: $system-font !important;
  font-size: 12px;
  font-smooth: always;
  -webkit-font-smoothing: antialiased;
  letter-spacing: 0.05em !important;
  min-height: 100vh;
  // font: normal 400 11px/16px Inter, Roboto, sans-serif !important;
  min-width: 100vw;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
}

.rotate-90 {
  transform: rotate(90deg);
}

.app-icon {
  background-color: #F1F1F1;
  //border: 1px solid #80808029;
  border-radius: 3px;
  padding: 2px;

  &.dashboard-app-icon {
    height: 40px;
    left: 0;
    margin: 0 auto;
    object-fit: contain !important;
    position: absolute;
    right: 0;
    top: 0;
    width: 40px;
  }
}

.rotate-180 {
  transform: rotate(180deg);
}

pre {
  font-weight: 400;

  .number-line {
    color: #ADADAF;
  }

  .string {
    color: #95C602;
  }

  .number {
    color: #F2B619;
  }

  .boolean {
    color: #0097F1;
  }

  .null {
    color: #727990;
  }

  .key {
    color: #FFFFFF;
  }
}

.optimize-img {
  image-rendering: -moz-crisp-edges;
  /* Firefox */
  image-rendering: -o-crisp-edges;
  /* Opera */
  image-rendering: -webkit-optimize-contrast;
  /* Webkit (non-standard naming) */
  image-rendering: crisp-edges;
  -ms-interpolation-mode: nearest-neighbor;
  /* IE (non-standard property) */
}

.center:not(custom-control-html .center) {
  left: 50%;
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

app-workarea {
  //cursor: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAAHYcAAB2HAY/l8WUAAAAYdEVYdFNvZnR3YXJlAHBhaW50Lm5ldCA0LjEuNWRHWFIAAAKHSURBVFhH7ZVNiFJRGIZvZlnTn/YzlYIzkEM/E0LUpoVRBCLUthCCJHDhYiLctBk3gZC1cCPlVogUJKXQhYpIO0GEQZFAF4oLFxmGSIk6U7f3DRWHGWZzvTILX3i453736nfO+33nXGGu/agDw+vMxcTKdrv9KBwOX8D4MFDwwazEZAutVuttr9f7Xa1Wn+P+BDgEZuLKQaCOxWJPOp3OZqPR6IFiOp2+h/jC8LmsogOnLRbLbREShHXRaDSKtVptADc+uN3uJTxXDd+TRbRZDa6jDG21Wo1JCKJCoRCtVutmvV7vViqVF4jJWpbj4HK5XN4wGAz/JzBCqVSKPp9vUCwWS3KW5ShYzmQyfrPZ/BfjbZMgWq2WZeljku88Ho8eMZZlam7QWq3f719zuVx9jHdMgIzKUiqVfsKRZ4jROSWQPBFaehbcTKVSuyafRKVSifl8vo+e2QiFQjcQo4OSy3IKrOZyuSbrjvGesFkxAW4cXt8kk8lFxCU5cQyswN6v3IYYb4P263Q6lmArGAxuFQqFX3wXffPKZrOt4h3+XtJWPQKW8IfvmQTjcXKNRiM2m00RSb9FIpGPDofjJeIPgQlcA1w9fy/JATbiRTYitx3G45XjHBjY7fZ13D8ATMq6XwE8pM4Brp7NKEm0b0cjYrV/EonEF4zvACbVgjOABxNXPZVdMNK4EdlkPJSy2ewPxO6CS8Pnsn4taaUBtf4cCATEbrc7cDqda4hdBUwu+4eJp5s+Ho+/5vaC9Z9wfwucB1y57GI9F71e72Os/rvJZLqP+2XA839qdd5LrO1JoI9Go09xZXJ+KWW3flIsAyfBHcHrTKyfFF1gKZh4qltsrrnm2kcShH/Vbh2pglHXnQAAAABJRU5ErkJggg==) 4 4,
  //auto !important;
  height: 100%;
  width: 100%;

  .mat-tab-list {
    //transform: translateX(0px) !important;
  }
}

.textfit {
  border: none;
}

app-dropdown-molecule,
app-textbox-molecule,
app-datepicker-molecule {
  .mat-form-field-infix {
    border-top: 0px;
    margin: 0;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }
}

app-datepicker-molecule {
  .mat-form-field-suffix {
    position: absolute;
    right: 0px;
    top: 50% !important;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }

  .mat-form-field-infix {
    position: absolute;
    width: calc(100% - 35px);
  }
}

app-label-molecule .textFitted {
  line-height: 0 !important;
  margin: 0px !important;
  margin-bottom: 0px !important;
  padding: 0px;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

app-checkbox-molecule mat-checkbox {
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 100%;

  label {
    margin-bottom: 0;
    width: 100%;

    .mat-checkbox-label {
      display: inline-block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 84%;
    }
  }
}

.develop .textfit {
  //height: auto !important;
}

#cobblesTreeContainer {
  .is-dragging-over-disabled {
    opacity: 1;
  }
}

.remove-context-menu {
  app-molecules-context-menu {
    transition: all 50ms;
    transition-delay: -0.5s;
    visibility: hidden !important;
  }

  app-molecules-context-menu {
    opacity: 0;
    transition: all 50ms ease-out;
  }
}

.open-dropdown-icon {
  font-size: 15px !important;
}

.ps__rail-x,
.ps__rail-y {
  z-index: 990;
}

.mat-menu-content {
  overflow: hidden !important;

  button {
    height: 40px !important;
  }
}

/*#dsTree,
#cobblesTree {
  tree-viewport {
    overflow: inherit !important;
  }
}*/

.wj-content {
  border: 0.5px solid rgba(181, 181, 181, 0) !important;
  border-radius: 0px !important;
}

.work-area-content {
  display: inline-block !important;
}

#work-area-wrapper {
  margin: auto;

  &.show-guideline {
    .gridster-row.guideline {
      border-top: 2px solid #EA5C2F !important;
    }

    .gridster-column.guideline {
      border-left: 2px solid #EA5C2F !important;
    }
  }
}

.mat-menu-panel {
  border-radius: 10px !important;
}

.node-content-wrapper {
  width: 100% !important;

  .ds-leaf {
    height: 14px;
  }
}

#spreadsheetSection {
  .angular-tree-component {
    margin-bottom: 10px;
  }

  .ds-leaf div {
    position: relative;
  }
}

.workbook-view {
  .spreadsheet-data-drag {
    background: -webkit-gradient(linear, left top, left bottom, from(white), to(#EEEEEE));
    background: linear-gradient(to bottom, white 0%, #EEEEEE 100%);
    border-radius: 16px;
    box-shadow: 0 2px 6px 0 rgba(22, 45, 61, 0.55);
    box-sizing: border-box;
    color: #2D4150;
    height: 30px;
    list-style: none;
    margin-left: 0px;
    min-width: 30px;
    padding-top: 3px;
    position: relative;
    text-align: center;
    text-decoration: none;
    transition: all 50ms;
  }

  .spreadsheet-data-drag:hover {
    color: #3F51B5;
    cursor: grab;
    transform: scale(1.1);
    transition: all 50ms;
  }
}

#dsTree {
  .tree-node {
    .node-wrapper {
      align-items: center;
      min-height: 36px;

      .node-content-wrapper {
        min-height: 36px;
      }

      .toggle-children {
        top: 0;
      }
    }
  }

  /*tree-viewport {
    margin: 0px 10px;
  }*/

  .tree-node-leaf {
    font-weight: 100;
  }

  .tree-node-expanded {
    color: #666666;
  }

  .ds-open {
    background-color: #EA5C2F;
    border-radius: 2px;
    color: white !important;
    font-weight: 600;
    padding: 3px 8px;

    .ds-name {
      color: white !important;
    }
  }

  .node-content-wrapper-focused {
    background-color: #EA5C2F;
    border-radius: 5px;
    color: white;
    padding: 5px;
  }
}

.mat-dialog-container {
  border-radius: 20px !important;
}

.custom-tooltip {
  background-color: black !important;
}

.tooltip-top::after {
  border-color: black transparent transparent !important;
}

.non-visible {
  opacity: 0 !important;
  transition: all 50ms;
  z-index: -1 !important;
}

// matsanckbar

.mat-snack-bar-container {
  //background: lightgray;
}

.mat-simple-snackbar {
  color: white;
  display: block !important;
  font-weight: 600;
  text-align: center;
}

//

.mat-bottom-sheet-container-xlarge {
  min-width: 400px !important;
}

.mat-bottom-sheet-container-large,
.mat-bottom-sheet-container-medium,
.mat-bottom-sheet-container-xlarge {
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
}

.workbook-view.selection-preview {
  .wj-flexgrid .wj-marquee {
    border-radius: 3px !important;
    box-shadow: 0 0 0 3px #5FDA42, inset 0 0 0 1px #08D421 !important;
  }

  .wj-state-multi-selected {
    background: #BCE8B1 !important;
  }
}

.workbook-view.selection-made {
  .wj-flexgrid .wj-marquee {
    border-radius: 3px !important;
    box-shadow: 0 0 0 3px #F12062, inset 0 0 0 1px #CC064F !important;
  }

  .wj-state-multi-selected {
    background: #FFBAD0 !important;
  }
}

#bottomSheetPanel {
  ul {
    list-style: none;
    margin: 0px;
    padding: 0px;
  }

  li {
    background-color: #F0F4F7;
    border-radius: 10px;
    list-style: none;
    margin: 8px 0px 9px 0px;
    padding: 8px;
    text-decoration: none;
  }

  li:hover {
    box-shadow: 0 1px 5px 0 rgba(41, 85, 115, 0.21);
    cursor: pointer;
    transform: scale(1.03);
    transition: all 50ms;
  }
}

// styles for rep molecules
// ----------------------------------

.work-group-wrapper {
  .mat-form-field-wrapper {
    height: 100% !important;
    padding: 0px !important;

    textarea {
      min-height: 30px !important;
    }
  }
}

.wrapper-element textarea.mat-input-element {
  overflow: hidden;
}

.wrapper-element .mat-form-field-flex {
  height: 100%;
}

.wrapper-element .mat-form-field {
  height: 100%;
}

app-textbox-molecule .wrapper-element .mat-form-field-wrapper {
  height: 100%;
  padding-bottom: 0px !important;
}

app-dropdown-molecule {
  .mat-form-field-infix {
    padding-top: 0px;
  }

  .mat-list-base .mat-list-item {
    height: 35px;
  }

  .mat-list-base {
    padding-top: 0px;
  }

  mat-list {
    overflow-x: hidden;
    overflow-y: auto;
  }

  .square-corners {
    .mat-form-field-outline-start,
    .mat-form-field-outline-end {
      border-radius: 0 !important;
    }
  }
}

.is-loading {
  color: transparent !important;
  pointer-events: none;

  .image,
  .node-content-wrapper,
  .mat-tab-label-content,
  .mat-form-field,
  button,
  h2,
  p,
  mat-chip {
    animation: 1.5s shine linear infinite;
    background: linear-gradient(120deg, #D8D8D8 8%, #F5F5F5 18%, #D8D8D8 33%);
    background: #EEEEEE;
    background-size: 200% 100%;
    border-radius: 5px;
    color: transparent !important;

    * {
      display: none;
    }
  }

  mat-chip {
    * {
      display: none;
    }
  }

  .image {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    height: 200px;
  }

  h2 {
    height: 30px;
  }

  p {
    height: 70px;
  }
}

@keyframes shine {
  to {
    background-position-x: -200%;
  }
}

// --------------------------------------
.infinite-pulse {
  -webkit-animation: pulse linear 1s infinite;
  animation: pulse linear 1s infinite;
}

.pulse,
.highlight-event-transaction {
  -webkit-animation: pulse linear 2s 2 forwards;
  animation: pulse linear 2s 2 forwards;
}

@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(225, 122, 44, 0.85);
  }

  70% {
    -webkit-box-shadow: 0 0 0 10px rgba(225, 122, 44, 0.37);
  }

  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(18, 143, 6, 0);
  }
}

@keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(225, 122, 44, 0.85);
  }

  70% {
    -webkit-box-shadow: 0 0 0 10px rgba(225, 122, 44, 0.37);
  }

  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(18, 143, 6, 0);
  }
}

// --------------------------------------

// --------------------------------------
.infinite-blur-animation {
  -webkit-animation: blur-animation linear 1s infinite;
  animation: blur-animation linear 1s infinite;
}

.blur-animation {
  -webkit-animation: blur-animation linear 1s 2 forwards;
  animation: blur-animation linear 1s 2 forwards;
}

@-webkit-keyframes blur-animation {
  0% {
    filter: blur(0px);
  }

  50% {
    filter: blur(0.7px);
  }

  100% {
    filter: blur(1.5px);
  }

  50% {
    filter: blur(0.7px);
  }

  0% {
    filter: blur(0px);
  }
}

@keyframes blur-animation {
  0% {
    filter: blur(0px);
  }

  50% {
    filter: blur(0.7px);
  }

  100% {
    filter: blur(1.5px);
  }

  50% {
    filter: blur(0.7px);
  }

  0% {
    filter: blur(0px);
  }
}

// --------------------------------------
.collaborator-pulse {
  -webkit-animation: collaborator-pulse linear 1s infinite;
  animation: collaborator-pulse linear 1s infinite;
}

@-webkit-keyframes collaborator-pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 #FF4081;
  }

  70% {
    -webkit-box-shadow: 0 0 0 10px #FF408038;
  }

  100% {
    -webkit-box-shadow: 0 0 0 0 #FF408000;
  }
}

@keyframes collaborator-pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 #FF4081;
  }

  70% {
    -webkit-box-shadow: 0 0 0 10px #FF408041;
  }

  100% {
    -webkit-box-shadow: 0 0 0 0 #FF408000;
  }
}

// gridster

.develop {
  gridster-item {
    border: 1px solid #B3B3B370;
    border-radius: 1px;
    overflow: initial;

    &.hide-elements-bounds {
      border: none;
    }
  }
}

gridster .gridster-column {
  // border-left: 1px dotted #e4e4e485 !important;
  // border-right: 1px dotted #e4e4e485 !important;
  border-left: 1px dotted #E08282D0 !important;
  border-right: 1px dotted #E08282D0 !important;
}

gridster .gridster-row {
  // border-top: 1px dotted #e4e4e485 !important;
  // border-bottom: 1px dotted #e4e4e485 !important;
  border-bottom: 1px dotted #E08282D0 !important;
  border-top: 1px dotted #E08282D0 !important;
}

gridster {
  background: transparent !important;
}

.develop .workgroup-gridster {
  //background: rgba(248, 248, 248, 0.445) !important;
  overflow: hidden;
}

.workgroup-gridster {
  padding: 0px !important;
}

.develop .work-group .workgroup-child gridster:not(.table-header-gridster) {
  background: rgb(248, 248, 248) !important;
  overflow: hidden;
}

.running .work-group gridster {
  background: transparent !important;
}

.develop .work-group .wrapper-element,
.work-group app-button-molecule .wrapper-element,
.work-group app-icon-molecule .wrapper-element {
  padding: 2px;

  .mat-form-field-infix {
    border-top: 0.44375em solid transparent;
  }
}

.work-group app-textbox-molecule .wrapper-element {
  padding: 0px;
}

.develop td:hover .move-column {
  display: inline-block !important;
}

.work-group .wrapper-element {
  display: flex;
  height: 100%;
  padding: 0px;
}

// disabling flex for custom controls
.work-group app-custom-molecule .wrapper-element {
  //align-items: center;
  display: block !important;
  //justify-content: center;

}

.develop .work-group:hover {
  .wrapper-element {
    height: 100%;
    //padding: 0px;
    // transition: all 50ms;
  }

  //.mat-form-field-infix {
  //  border-top: 0.84375em solid transparent !important;
  //}
}

.wrapper-element {
  word-break: break-word;

  .mat-form-field-appearance-outline .mat-form-field-infix {
    padding: 0px !important;
  }

  // for spinner
  div.spinner-molecule {
    svg {
      height: 100% !important;
      width: 100% !important;
    }
  }
}

.workgroup-gridster gridster .gridster-column,
.workgroup-gridster gridster .gridster-row {
  border: none !important;
}

gridster.workgroup-gridster {
  background: transparent !important;
}

gridster.workgroup-gridster gridster {
  padding: 0px !important;
}

.mat-form-field-appearance-outline .mat-form-field-wrapper {
  margin: 0px !important;
}

.table-gridster {
  .context-actions {
    top: -5px !important;
  }
}

//

input {
  //box-shadow: none !important;
}

.table-container .mat-form-field-label {
  font-size: small;
}

a:hover,
img:hover {
  text-decoration: none;
}

* {
  border-color: #D3D5D9;
}

.no-shadows {
  box-shadow: none !important;
}

[dnd-droppable] {
  display: inline-block;
  position: relative;
}

.molecules-tooltip-icon {
  color: goldenrod;
  // position: absolute;
  // top: -7px;
  // left: -8px;
  z-index: 1;
}

.molecules-tooltip-icon:hover {
  color: rgb(150, 7, 7);
}

.mat-tooltip {
  font-size: 14px !important;
  text-align: center;
}

.mat-chip {
  padding: 3px 9px !important;
}

.panel-viewer-title {
  align-content: center;
  align-items: center;
  -webkit-box-align: center;
  -webkit-box-pack: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  -ms-flex-line-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 0px 5px;
}

.panel-viewer-wrapper {
  border: 2.5px solid rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  position: relative;

  &:hover {
    box-shadow: 0 1px 5px 0 rgba(41, 85, 115, 0.21);
    transform: translateY(-1px);
    transition: all 150ms;
  }
}

.panel-viewer-title {
  background: rgba(0, 0, 0, 0.03);
}

.panel-viewer-title-spacer {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
}

.panel-viewer-body {
  padding: 0px 4px;
}

.mat-expansion-panel-body {
  padding: 0 12px 16px !important;
}

.data-container {

  mat-accordion {
    .mat-expansion-panel-body {
      border-bottom: 1px solid #BABABA;
      font-family: "Nunito", sans-serif !important;
      font-size: 12px !important;
      padding: 0 14px 8px !important;
    }

    mat-expansion-panel {
      &:last-child {
        .mat-expansion-panel-body {
          border-bottom: none;
        }
      }
    }
  }


}

.reorder-molecule,
.remove-molecule {
  visibility: hidden;
  width: 0px !important;
}

.molecule-setting-draggable:hover {
  .reorder-molecule,
  .remove-molecule {
    color: #9BA3AF;
    visibility: visible;
    width: 20px !important;
  }
}

.disallow {
  .mat-form-field-outline-thick {
    color: red !important;
  }
}

mat-form-field {
  width: 100%;
}

.multiple-selection-properties {
  mat-form-field {
    width: 75% !important;
  }
}

.mat-table-container {
  display: flex;
  flex-direction: column;
  min-width: 70vw;
}

.mat-table-header {
  min-height: 64px;
  padding: 8px 24px 0;
}

.show-element {
  display: inline !important;
}

.wrapper-element.develop.hide-element {
  display: inline !important;
  opacity: 0.5;
}

.hide-element {
  display: none !important;
}

// dnd - draggable
.dnd-drag-over,
.highlight-drop {
  border: 2px dashed #287FA7 !important;
  border-radius: 10px !important;
  display: inherit !important;
  padding: 2px;
  transition: border-color 0.2s linear;
  transition: all 50ms;
}

.dnd-drag-over {
  border-style: solid;
  transform: scale(1.2);
  transition: all 50ms;
}

.highlight-drop-no {
  background-color: #F443361F;
  border: 2px dashed #DC3545 !important;
  border-radius: 10px !important;
  display: inherit !important;
  padding: 2px;
  transition: border-color 0.2s linear;
}

.dnd-drag-enter.highlight-drop {
  border-color: #9DE4F6 !important;
}

.dnd-sortable-drag {
  border: 2px dashed #717171 !important;

  .bus {
    border: 2px solid gray !important;
    margin: 5px !important;
    width: auto !important;

    .bus-name {
      i {
        display: none !important;
      }
    }
  }
}

.highlighter-veil {
  display: none;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.dnd-drag-start {
  border: 2px dashed #000000 !important;
  border-radius: 17px;
  opacity: 0.7 !important;
  transform: scale(0.95) !important;
}

pre {
  background: #F4F4F4;
  border: 1px solid #DDDDDD;
  border-left: 3px solid #4F596A;
  color: #666666;
  display: block;
  font-family: Courier, 'New Courier', monospace;
  font-size: 15px;
  font-weight: bold;
  line-height: 1.6;
  margin-bottom: 1.6em;
  max-width: 100%;
  padding: 1em 1.5em;
  page-break-inside: avoid;
  white-space: pre-wrap;
  width: 100%;
}

.bold {
  font-weight: 600 !important;
}

.ng-draggable {
  position: absolute;
  z-index: 0;
}

.disable-selection {
  -webkit-touch-callout: none;
  /* Firefox */
  -khtml-user-select: none;
  /* Internet Explorer */
  -moz-user-select: none;
  /* KHTML browsers (e.g. Konqueror) */
  -ms-user-select: none;
  /* Chrome, Safari, and Opera */
  -webkit-user-select: none;
  /* Disable Android and iOS callouts*/
}

.wj-tooltip {
  background-color: #E9E9E9;
  color: #333333;
  z-index: 9999999999 !important;
}

.wj-series-group {
  cursor: pointer;
}

t-drop-library-effect:hover {
  border: 2px solid lightslategrey;
  border-radius: 3px;
  box-shadow: 0px 0px 15px 4px rgba(0, 0, 0, 0.17);
  cursor: copy !important;
  height: 48px;
  transform: scale(1.02, 1.05);
}

.element-drop-library-effect {
  border: 2px dotted rgb(187, 187, 187);
  border-radius: 4px;
  margin-top: 3px;
  transition: all 0.5s;
}

.library-drop-element-content {
  font-size: 17px;
  width: 100%;
}

.library-drop-element-text {
  padding-top: 5px;
  position: absolute;
}

@keyframes breath_animation {
  0% {
    transform: scale(1);
  }

  25% {
    transform: scale(1.05);
  }

  50% {
    transform: scale(1);
  }

  75% {
    transform: scale(1.05);
  }

  100% {
    transform: scale(1);
  }
}

.breath {
  animation-duration: 30050ms;
  animation-iteration-count: infinite;
  animation-name: breath_animation;
  animation-timing-function: linear;
  transform-origin: 70% 70%;
}

.slow-breath {
  animation-duration: 50050ms;
  animation-iteration-count: infinite;
  animation-name: breath_animation;
  animation-timing-function: linear;
  transform-origin: 70% 70%;
}

.show-animation {
  height: auto !important;
  opacity: 1 !important;
  transition: all 5050ms;
}

.incompatible-molecules {
  background-color: #FFFFFF;
  border: 1px solid lightgray;
  border-radius: 10px;
  box-shadow: 0 11px 15px -7px rgba(0, 0, 0, 0.2), 0 24px 38px 3px rgba(0, 0, 0, 0.14), 0 9px 46px 8px rgba(0, 0, 0, 0.12);
  box-sizing: border-box;
  color: #AB2020;
  display: block;
  height: 0;
  opacity: 0;
  outline: 0;
  overflow: auto;
  padding: 13px;
  position: absolute;
  text-align: center;
  width: fit-content;
  z-index: 99999999;
}

.incompatible-molecules ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.incompatible-molecules h5 {
  background: #E8E8E8;
  border-radius: 3px;
  padding: 3px;
}

.selected-tree {
  background: whitesmoke;
}

.mat-tree-node [dnd-droppable] {
  cursor: pointer;
  width: 100%;
}

.mat-tree-node {
  list-style: none;
}

.hover-show-mat-icon .mat-icon,
.hover-show-mat-icon .material-icons-round {
  display: none;
}

.hover-show-mat-icon:hover .mat-icon,
.hover-show-mat-icon:hover .material-icons-round {
  display: inline-block !important;
}

.mat-radio-button {
  padding: 5px;
}

.mat-tree-node .clickable {
  cursor: pointer;
}

.hide-cobble .work-group.wrapper-element {
  opacity: 0;
}

.mat-tooltip {
  white-space: pre;
}

.opened-node {
  color: blue;
  font-size: 14px;
  padding-left: 10px;
}

// dropdown
.options-list .mat-list-item-content {
  padding: 0px !important;
}

//
.running-mode .work-group-header {
  display: none !important;
}

.ngxp__container {
  background: #FFFFFF;
  color: black;
  padding: 10px;
  text-align: center;
}

.advanced-molecule {
  height: 0px;
  visibility: hidden;
}

.show-advanced-molecules .advanced-molecule {
  height: 100%;
  visibility: visible;
}

.mat-tab-body-wrapper {
  flex-grow: 1;
}

.mat-tab-group {
  height: 100%;
}

.z-index-1 {
  z-index: 1 !important;
}

.develop .wrapper.dropdown {
  display: none !important;
}

.develop {
  .workgroup-child,
  mat-form-field,
  .mat-input-element,
  .mat-datepicker-toggle-default-icon {
    cursor: pointer !important;
  }

  .workgroup-child:hover {
    border: 1px solid #EA5C2FB0;
  }

  .workgroup-child.locked:hover {
    border: 1px solid #5E7DC0;
  }

  .wrapper-element {
    app-iframe {
      pointer-events: none !important;
    }
  }

  gridster-item.gridster-item-moving,
  gridster-item.gridster-item-resizing {
    box-shadow: none !important;
    z-index: 99999 !important;
  }

  mat-stepper {
    border-bottom: 2px solid lightgrey;

    .step-disabled {
      pointer-events: none;
    }

    .mat-horizontal-stepper-header-container,
    .mat-vertical-stepper-header {
      mat-step-header:nth-child(3),
      mat-step-header:nth-child(5) {
        pointer-events: none;
      }
    }

    .mat-vertical-stepper-header {
      border-bottom: 2px solid lightgrey;
    }
  }

  .workgroup-child {
    .gridster-item-resizable-handler {
      border-color: #EA5C2F !important;
    }

    &.element-selected {
      border: 2px solid #EA5C2F;
      z-index: 9999999999999999 !important;

      .wrapper-element {
        height: 100%;
        padding: 0px;
      }

      .gridster-item-resizable-handler {
        border-color: transparent transparent #EA5C2F;
      }
    }

    &.locked {
      &.element-selected {
        border: 2px solid #5E7DC0;
      }

      .gridster-item-resizable-handler {
        display: none !important;
        // border-color: #5e7dc0 !important;
        // border-width: 2px !important;
        // pointer-events: none;
      }
    }
  }

  gridster-item.element-selected {
    border: 2px solid #EA5C2F;

    .wrapper-element {
      height: 100%;
      padding: 0px;
    }

    .gridster-item-resizable-handler {
      border-color: transparent transparent #EA5C2F;
    }
  }
}

// ngx pagination
.ngx-pagination a {
  color: rgba(0, 0, 0, 0.54) !important;
}

.ngx-pagination .current {
  background: rgb(220 229 243) !important;
  background-color: #EA5C2F !important;
  border-radius: 4px;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  color: #3E3E3E !important;
  // box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
  //   0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.ngx-pagination a:hover,
.ngx-pagination button:hover {
  background: #E6E6E6;
  border-radius: 4px;
  color: black !important;
  text-decoration: none;
}

.mat-menu-panel {
  min-height: 55px !important;
}

.mat-menu-item {
  color: black;
  font-weight: 600;
  line-height: 42px !important;
}

.running-mode {
  gridster .gridster-column,
  gridster .gridster-row,
  gridster-item,
  gridster-item.gridster-item-resizing,
  gridster-item.gridster-item-moving {
    transition: unset !important;
  }
}

#views {
  .angular-tree-component {
    overflow-y: auto;
  }
}

.viewspanel-visible {
  z-index: 998 !important;

  #ViewsPanel,
  .layouts-container {
    display: inline !important;
    opacity: 1 !important;
    transition: all 50ms;
  }

  &.notification {
    display: inline !important;
    opacity: 1 !important;
    transition: all 50ms;
  }
}

.mat-form-field-appearance-outline .mat-form-field-flex {
  margin-top: -1.5px !important;
}

gridster-item {
  background: transparent !important;
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(-360deg);
  }
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(-360deg);
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(-360deg);
    transform: rotate(-360deg);
  }
}

@keyframes zoomInOut {
  0% {
    transform: scale(1, 1);
  }

  50% {
    transform: scale(1.1, 1.1);
  }

  100% {
    transform: scale(1, 1);
  }
}

.zoom-in-out {
  animation: zoomInOut 1s infinite;
}

@keyframes fade-loop-frame {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.2;
  }

  100% {
    opacity: 1;
  }
}

.fade-loop {
  animation: fade-loop-frame 1s infinite;
}

body {
  font-family: $system-font;
}

.wj-cell.wj-alt {
  background: #F7F7F7C9 !important;
}

.running table .workgroup-child {
  //height: 100% !important;

  &.table-child {
    max-width: 90% !important;
  }
}

.mat-fab.mat-primary,
.mat-flat-button.mat-primary,
.mat-mini-fab.mat-primary,
.mat-raised-button.mat-primary {
  background-color: #EA5C2F;
}

.mat-checkbox-checked.mat-accent:not(.mat-checkbox-disabled.mat-checkbox-checked) .mat-checkbox-background,
.mat-checkbox-indeterminate.mat-accent:not(.mat-checkbox-disabled.mat-checkbox-checked) .mat-checkbox-background {
  background-color: #EA5C2F;
}

.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
  background: #EA5C2F;
}

.mat-button.mat-accent,
.mat-icon-button.mat-accent,
.mat-stroked-button.mat-accent {
  color: #EA5C2F;
}

.mat-button.mat-accent .mat-button-focus-overlay,
.mat-icon-button.mat-accent .mat-button-focus-overlay,
.mat-stroked-button.mat-accent .mat-button-focus-overlay {
  background-color: #EA5C2F;
}

.mat-slide-toggle.mat-checked .mat-ripple-element {
  background-color: #EA5C2F;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #EA5C2F;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(234, 92, 47, 0.45);
}

.mat-progress-bar-buffer {
  background-color: #E5957C;
}

.mat-progress-bar-fill::after {
  background-color: #EA5C2F;
}

.mat-slider:not(.slider-molecule).mat-accent {
  .mat-slider-thumb,
  .mat-slider-thumb-label,
  .mat-slider-track-fill {
    background-color: black;
  }
}

.slider-molecule {
  &.mat-slider-vertical {
    min-height: 100% !important;
  }

  &.mat-slider-horizontal {
    min-width: 100%;
  }
}

.mat-slider-horizontal:not(.slider-molecule) {
  min-width: 100px !important;
}

// json tree
.jsoneditor {
  &.jsoneditor-mode-view {
    border: none !important;
  }

  .jsoneditor-field {
    font-weight: 700;
  }

  .jsoneditor-menu {
    background-color: black;
    border: none;
  }
}

// json tree

.custom-element-tooltip {
  background-color: white;
  border: 1px solid lightgray;
  color: black !important;
  font-weight: 500;
  max-width: unset !important;
}

.custom-editor-tooltip {
  font-size: 12px !important;
  max-width: unset !important;
}

.gridster-item-resizable-handler {
  display: none;

  &.handle-e,
  &.handle-w {
    width: 3px !important;
  }

  &.handle-n,
  &.handle-s {
    height: 3px !important;
  }

  &.guideline-vertical {
    width: 1px !important;
  }

  &.guideline-horizontal {
    height: 1px !important;
  }

  &.handle-nw {
    border-style: solid;
    left: -3px !important;
    top: -3px !important;
  }

  &.handle-sw {
    border-style: solid;
    bottom: -3px !important;
    left: -3px !important;
  }

  &.handle-ne {
    border-style: solid;
    border-width: 0 0 10px 10px;
    right: -3px !important;
    top: -3px !important;
  }

  &.handle-se {
    bottom: -3px !important;
    right: -3px !important;
  }

  &.handle-se,
  &.handle-ne,
  &.handle-sw,
  &.handle-nw {
    border-color: transparent transparent #CCCCCC;
    border-width: 3px !important;
    height: 5px !important;
    width: 5px !important;
  }
}

.workgroup {
  & > handle-nw {
    transform: rotate(319deg);
  }

  & > handle-sw {
    transform: rotate(225deg);
  }

  & > handle-ne {
    transform: rotate(45deg);
  }

  & > handle-se {
    transform: rotate(135deg);
  }
}

gridster-item:hover {
  & > .gridster-item-resizable-handler {
    display: block;
  }
}

gridster-item.dragover {
  border: 2px dashed #EA5C2F !important;
  border-radius: 3px;
  transition: all 50ms;
}

.no-handler .gridster-item-resizable-handler {
  border-width: 0 0 0 0 !important;
  height: 0px !important;
  width: 0px !important;
}

.remember-me-checkbox .mat-checkbox-frame {
  border-color: white !important;
}

.debug {
  .work-group.wrapper-element {
    border: 2px solid red;
  }

  &.grid.preview {
    top: 50px;
  }

  .event-color-identifier {
    display: inline-block !important;
  }
}

.marvel-device {
  box-shadow: 0 3px 16px 0 rgba(88, 97, 103, 0.6) !important;
}

.mat-form-field-appearance-outline .mat-form-field-outline {
  background: transparent;
}

.wrapper.wrapper-element mat-form-field {
  border-radius: 5px;
}

.border-busy-indicator {
  border-radius: 10px;
  overflow: hidden !important;
  padding: 2px;
  z-index: 0;

  .work-group {
    background: white;
  }

  &.critic-border-busy-indicator {
    &::before {
      background-image: linear-gradient(rgb(218, 64, 64), rgb(218, 64, 64)), linear-gradient(transparent, transparent),
      linear-gradient(rgb(218, 64, 64), rgb(218, 64, 64)), linear-gradient(transparent, transparent) !important;
    }
  }

  &::before {
    animation-duration: 2000ms;
    animation-iteration-count: infinite;
    animation-name: spin;
    animation-timing-function: linear;
    background-image: linear-gradient(#1579D6D2, #1579D6D2), linear-gradient(transparent, transparent), linear-gradient(#1579D6D2, #1579D6D2),
    linear-gradient(transparent, transparent);
    background-position: 0 0, 100% 0, 100% 100%, 0 100%;
    background-repeat: no-repeat;
    background-size: 50% 50%, 50% 50%;
    content: '';
    height: 400%;
    left: -150%;
    min-height: 100px;
    min-width: 100px;
    position: absolute;
    top: -150%;
    width: 400%;
    z-index: -2;
  }

  .spin {
    animation-duration: 5000ms;
    animation-iteration-count: infinite;
    animation-name: spin;
    animation-timing-function: linear;
  }

  @-ms-keyframes spin {
    from {
      -ms-transform: rotate(0deg);
    }

    to {
      -ms-transform: rotate(360deg);
    }
  }

  @-moz-keyframes spin {
    from {
      -moz-transform: rotate(0deg);
    }

    to {
      -moz-transform: rotate(360deg);
    }
  }

  @-webkit-keyframes spin {
    from {
      -webkit-transform: rotate(0deg);
    }

    to {
      -webkit-transform: rotate(360deg);
    }
  }

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }

    to {
      transform: rotate(360deg);
    }
  }
}

.green-animation-pulse {
  animation: pulse-green 1.3s;
  box-shadow: 0 0 0 0 rgb(51, 217, 140);
}

@keyframes pulse-green {
  0% {
    box-shadow: 0 0 0 0 rgba(51, 217, 140, 0.7);
  }

  70% {
    box-shadow: 0 0 0 10px rgba(51, 217, 140, 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(51, 217, 140, 0);
  }
}

.danger-animation-pulse {
  animation: pulse-red 2s infinite;
  box-shadow: 0 0 0 0 rgb(192, 46, 46);
}

@keyframes pulse-red {
  0% {
    box-shadow: 0 0 0 0 rgba(192, 46, 46, 0.7);
  }

  70% {
    box-shadow: 0 0 0 10px rgba(192, 46, 46, 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(192, 46, 46, 0);
  }
}

.overlay {
  background-color: rgba(0, 0, 0, 0.25);
  bottom: 0;
  display: block;
  height: 100%;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 2;
}

#loadingOverlay {
  background-color: rgba(0, 0, 0, 0.25);
  bottom: 0;
  display: block;
  height: 100%;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 2;

  @keyframes bounce {
    from {
      transform: translateY(0px);
    }

    to {
      transform: translateY(-10px);
    }
  }

  @-webkit-keyframes bounce {
    from {
      transform: translateY(0px);
    }

    to {
      transform: translateY(-10px);
    }
  }

  .loading-overlay-body {
    background-color: rgb(205 205 205);
    border-radius: 4px;
    padding: 10px;

    .loading-overlay-icon-animation {
      -webkit-animation: bounce 0.7s infinite alternate;
      animation: bounce 0.7s infinite alternate;
    }

    .icon-container {
      height: 55px;
      margin: 0 auto;
      margin-bottom: 10px;
      width: 55px;

      i {
        font-size: 55px;
        margin-bottom: 10px;
      }
    }

    .spinner-container {
      margin-bottom: 10px;
    }

    .message {
      font-size: 20px;
      font-weight: bold;
    }

    mat-spinner {
      margin: 0 auto;
    }
  }
}

app-h1-molecule .textFitted,
app-h2-molecule .textFitted,
app-h3-molecule .textFitted,
app-h4-molecule .textFitted,
app-h5-molecule .textFitted,
app-h1-molecule .textfit,
app-h2-molecule .textfit,
app-h3-molecule .textfit,
app-h4-molecule .textfit,
app-h5-molecule .textfit,
app-label-molecule .wrapper-element,
app-h1-molecule .rep-mol-text,
app-h2-molecule .rep-mol-text,
app-h3-molecule .rep-mol-text,
app-h4-molecule .rep-mol-text,
app-h5-molecule .rep-mol-text,
app-label-molecule .rep-mol-text {
  display: block !important;

  //margin: 0px !important;
  //padding: 0px;
  //top: 50%;
  //-ms-transform: translateY(-50%);
  //transform: translateY(-50%);
  //position: absolute;
  //width: 100%;
  //white-space: nowrap;
  //text-overflow: ellipsis;
  //height: auto !important;
  //min-height: 100%;

  //overflow: hidden;
}

.notification {
  display: none;
  height: 100px;
  opacity: 0;
  position: absolute;
  right: 20px;
  top: 65px;

  .alert {
    background: #EEEEEE;
    border: 1px solid #EA5C2F;
    border-radius: 5px;
    bottom: 0;
    box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
    display: grid;
    grid-template-columns: 100px 1fr;
    grid-template-rows: 1fr 30px;
    height: 185px;
    left: 0;
    margin: auto;
    place-content: center;
    right: 0;
    top: 0;
    width: 500px;

    &-icon {
      border: 2px solid #EF5350;
      border-radius: 50%;
      display: grid;
      height: 30px;
      margin: auto;
      place-content: center;
      place-items: center;
      width: 30px;

      &::before {
        animation: r 0.7s linear infinite;
        border-bottom: 1px solid #EF5350;
        border-left: 1px solid transparent;
        border-radius: 50%;
        border-right: 1px solid transparent;
        border-top: 1px solid #EF5350;
        content: '';
        height: 50px;
        left: 40px;
        margin: auto;
        position: absolute;
        top: 51px;
        width: 50px;

        @keyframes r {
          to {
            transform: rotate(360deg);
          }
        }
      }
    }

    &-message {
      border-left: 2px solid #EF5350;
      font-size: 11px;
      margin: auto;
      max-width: 400px;
      padding-left: 1rem;
      width: 90%;
    }

    &-dismiss {
      background: #D3D3D347;
      border-radius: 5px;
      color: #E57373;
      cursor: pointer;
      display: grid;
      font-size: 16px;
      font-weight: 900;
      justify-self: right;
      letter-spacing: 1px;
      place-content: center;
      text-transform: uppercase;
      transition: all 150ms;
      width: 200px;

      &:hover {
        background: lightgray;
        transition: all 150ms;
      }
    }
  }
}

.warning-border {
  border: 3px solid orangered !important;
  border-radius: 5px !important;

  & > .error-saving-indicator {
    animation: pulse-red 2s infinite;
    border-radius: 50%;

    box-shadow: 0 0 0 0 rgb(192, 46, 46);
    color: red;
    display: block !important;
    font-size: 25px;
    height: 25px;
    position: absolute;
    right: -16px;
    top: -17px;
    width: 25px;
    z-index: 9;
  }
}

.error-saving-indicator {
  display: none;
}

.hide-floating-window {
  display: none !important;
  opacity: 0 !important;
  z-index: -9999999999 !important;
}

.mat-expanded {
  .mat-expansion-panel-content {
    height: 100% !important;
    visibility: visible !important;
  }
}

.Compounds-library {
  .mat-expansion-panel-content {
    height: 136px !important;
    overflow: auto;
  }
}

.drag-data-element-here {
  border: 2px dotted #EA5C2F;
  transform: scale(1.03);
  transition: all 200ms;
}

//// events tree/////
.event-section,
#dsTreeContainer,
#cobblesTree,
.datasource-section-body {
  .events-drag,
  .node-content-wrapper[draggable='true'] {
    & .name:not(.name-ds-open, .ds-highlighted):hover {
      background: #EAEAEA9C;
      border: 2px solid lightgray;
      // transform: translate(5px, -1px);
      border-radius: 2px;
      box-shadow: -4px 4px 11px -2px #B3B3B3;
      cursor: grab;
      padding: 2px 5px;
      transition: all 150ms;

      .remove-custom-value {
        display: inline !important;
      }
    }

    & .ds-open:hover, & .ds-highlighted:hover {
      background: #EA5C2FCC;
      border: 2px solid lightgray;
      border-radius: 2px;
      box-shadow: -1px 3px 3px 0px #B3B3B36E;
      cursor: grab;
      transition: all 150ms;

      .remove-custom-value {
        display: inline !important;
      }
    }
  }
}

app-events-panel {
  .name {
    display: inline;
  }
}

.library-item-chip {
  height: 28px;

  margin: 2px !important;
  min-height: 28px !important;

  &:hover {
    box-shadow: inset 0 0 1px #999999;
    box-shadow: -4px 4px 11px -2px rgba(179, 179, 179, 1);
    transform: translate(-5px, -2px);
    transition: all 150ms;
  }
}

#eventsTree,
#dsTreeContainer,
#viewsTree {
  .node-content-wrapper,
  .tree-children {
    position: relative;
  }

  .node-content-wrapper::before,
  .tree-children::after {
    content: '';
    position: absolute;
  }

  .node-content-wrapper::before {
    border-bottom: 1px solid lightgrey;
    border-left: 1px solid lightgrey;
    height: 28px;
    left: -28px;
    top: -17px;
    width: 20px;
  }

  .tree-node-level-1 > tree-node-wrapper > .node-wrapper > .node-content-wrapper::before {
    display: none;
  }

  .tree-node-leaf > .node-wrapper > .node-content-wrapper::before {
    width: 25px;
  }

  .tree-children::after {
    border-left: 1px solid lightgrey;
    height: 100%;
    left: -15px;
    top: -15px;
  }

  tree-node:last-child > .tree-node > .tree-children::after {
    border-left: none;
  }

  .toggle-children {
    z-index: 1;
  }

  .icon {
    color: #BABECE;
    font-size: 14px;
    margin-top: -2px;
    vertical-align: middle;
  }
}

/////////////////////

#main-topbar {
  .debug-checkbox {
    .mat-checkbox-frame {
      background-color: white !important;
    }
  }
}

.hover-pointer {
  &:hover {
    cursor: pointer;
  }
}

.hover {
  cursor: pointer;

  &:hover {
    transform: scale(1.02);
    transition: all 150ms;
  }
}

.node-content-wrapper-active.node-content-wrapper-focused {
  // background: #73c11e54 !important;
  background: transparent !important;
}

.node-content-wrapper-active,
.node-content-wrapper-focused,
.node-content-wrapper:hover {
  // box-shadow: inset 0 0 1px #8dff80 !important;
  box-shadow: inset 0 0 1px transparent !important;
}

.node-content-wrapper:hover {
  // background: #f7fff7 !important;
  background: transparent !important;
}

#leftSplitArea,
#rightSplitArea {
  #mat-tab-content-0-0 {
    overflow: hidden !important;
    overflow-y: hidden !important;
  }
}

#leftSplitArea {
  .mat-tab-body-content {
    overflow-y: hidden;
  }
}

#rightSplitArea {
  .mat-tab-body-content {
    overflow: hidden;
  }
}

data-sources-panel {
  .node-content-wrapper-focused {
    background: #EA5C2F17 !important;
    border: 2px solid #EA5C2F;
    border-radius: 6px;
    padding: 0px 4px !important;
  }
}

.disable-particle {
  #trapezoid {
    display: none !important;
  }
}

.drag-selection {
  background: rgba(234, 92, 47, 0.22);
  border: 2px solid #EA5C2F;
  border-radius: 0.1em;
}

.mat-form-field-appearance-outline.required-start .mat-form-field-outline-thick {
  color: #F44336;
}

#debug-enabler {
  bottom: 0;
  color: gray;
  cursor: pointer;
  position: fixed;
  right: 0;
  z-index: 99999999999999;

  i {
    background: white;
    border: 1px solid gray;
    border-radius: 50px;
    font-size: 20px !important;
    margin-bottom: 13px;
    margin-right: 3px;
  }
}

//.selected-element-view {
//  background-color: #EA5C2F;
//  display: inline;
//  color: white;
//  border-radius: 4px;
//
//  .element-icon {
//    color: white !important;
//  }
//}

.selected-element-node {
  background-color: #EA5C2F;
  border-radius: 2px;
  color: white !important;
  font-weight: 600;
  padding: 3px 8px;

  i {
    color: white !important;
  }

  .name {
    font-size: 12px !important;
  }

  .view-actions {
    background-color: #EA5C2F !important;
  }

  .element-icon {
    color: white !important;
  }

  .last-used-element {
    color: white !important;
  }
}

.ds-highlighted {
  background-color: #EA5C2F;
  border-radius: 2px;
  color: white !important;
  font-size: 12px;
  font-weight: 600;
  padding: 3px 8px;

  .view-actions {
    background-color: #E2E2E299 !important;
  }

  .element-icon {
    color: #EA5C2F !important;
  }
}

.node-wrapper:hover {
  background-color: #E2E2E299;
  //background-color: #D9F3FB;
  border-radius: 5px;

  //transition: all 150ms;

  .view-actions {
    background-color: transparent !important;
  }
}

.highlight-receptor {
  background-color: #EA5C2F;
  color: white;
  font-weight: 600;

  mat-icon {
    color: white;
  }

  &:hover {
    color: #EA5C2F;

    mat-icon {
      color: #EA5C2F;
    }
  }
}

.highlight-rep-molecule {
  border: 2px solid #EA5C2F !important;
  box-shadow: 0 0 0 1600px rgba(0, 0, 0, 0.22);
  z-index: 99999999999999 !important;
}

.develop .element-on-top {
  z-index: 9999999999999999 !important;
}

.wj-state-active {
  &:hover {
    cursor: grab;
  }
}

[draggable='true']:hover {
  transform: translate3d(0, 0, 0);
}

*::-webkit-scrollbar {
  height: 8px;
  width: 8px;
}

*::-webkit-scrollbar-track {
  background-color: rgba(141, 141, 141, 0.05);
}

*::-webkit-scrollbar-thumb {
  background-clip: content-box;
  background-color: #D4D1D1;
  border: 2px solid transparent;
  border-radius: 15px;
}

*::-webkit-scrollbar-thumb:hover {
  background-color: gray;
}

*::-webkit-scrollbar-corner {
  background-color: rgba(141, 141, 141, 0.05);
}

.work-area-content.as-split-area {
  overflow-x: auto !important;

  &.pan-zoom {
    cursor: grab;
    overflow-x: hidden !important;
    overflow-y: hidden !important;

    .work-area-gridster {
      border: 2px solid lightgray;
      box-shadow: none !important;
    }
  }
}

.d-ib {
  display: inline-block;
}

.f-right {
  float: right;
}

.draggable-inside-container {
  display: inline-block;
  height: calc(100% - 48px);
  overflow-y: overlay;
  padding: 10px;
  width: 100%;

  &.footer {
    height: calc(100% - 93px) !important;
  }

  &.process-panel {
    &.horizontal-layout-container {
      column-gap: 5px;
      display: flex;
    }

    .horizontal-layout {
      column-gap: 5px;
      display: flex;
      flex-grow: 1;
    }
  }
}

#trapezoid {
  border-bottom: 4px solid rgba(0, 0, 0, 0.2) !important;
  border-left: 5px solid transparent !important;
  border-right: 5px solid transparent !important;
  margin: 0 auto;
  transform: rotate(180deg);
  width: 23px;
}

.mat-button.mat-primary,
.mat-icon-button.mat-primary,
.mat-stroked-button.mat-primary {
  color: #EA5C2F !important;
}

.mat-fab.mat-accent,
.mat-flat-button.mat-accent,
.mat-mini-fab.mat-accent,
.mat-raised-button.mat-accent {
  background-color: #EA5C2F !important;
}

app-events-transaction {
  #locationDataWindow {
    background-color: #E8E5E5;
    border-radius: 10px;
    box-shadow: 0 0px 15px 3px rgb(0 0 0 / 17%), 0 24px 38px 3px rgb(0 0 0 / 14%), 0 9px 46px 8px rgb(0 0 0 / 12%);
    height: 250px;
    position: absolute;
    width: 200px;

    .data-container {
      height: calc(100% - 5px);
      overflow: auto;
      padding: 5px;
      width: calc(100% - 5px);
    }
  }

  .main-container {
    background-color: #EDF2F7;
    background-image: radial-gradient(#DBDBDB 20%, #EFEFEF 20%);
    background-position: 0 0;
    background-size: 10px 10px;
    height: calc(100% - 5px);
    width: calc(100% + 50px);
  }

  .data-in,
  .data-out {
    height: 24px;
    text-align: center;

    .data-icon {
      cursor: pointer;
      display: inline-block;
      height: 20px;
      transform: rotate(90deg);
      width: 20px;

      i {
        font-size: 20px;
      }
    }
  }

  .output-receptor {
    transform: rotate(90deg);
  }

  .input-receptor {
    transform: rotate(-90deg);
  }

  .none-receptor {
    transform: rotate(-90deg);
  }

  .node {
    display: contents;
  }

  .busContainer {
    background-color: black;
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
    color: lightgrey;
    height: 15px;
    margin: 0 5px;
    text-align: center;

    .bus-name {
      display: inline-block;
      height: 16px;
      max-width: 207px;
      overflow: hidden;
      text-overflow: ellipsis;
      vertical-align: top;
      white-space: nowrap;
    }

    span {
      vertical-align: text-bottom;
    }

    i {
      font-size: 14px;
      height: 10px;
      margin-right: 2px;
      position: relative;
      top: 3px;
    }
  }

  .particlesContainer {
    border: 2px solid black;
    border-bottom: 5px solid black;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    height: 66px;
    margin: 0px 5px;
    overflow: auto;
    padding: 3px;
    position: relative;

    .completed-icon {
      float: right;
      font-size: 17px;
      margin-top: 1px;
    }

    .particles-list {
      list-style: none;
      margin: 0px;
      padding: 0px;

      li {
        border: 3px solid rgba(0, 0, 0, 0.2);
        border-radius: 9px;
        margin-bottom: 1px;
        padding: 0 4px;
        position: relative;
      }

      .completed {
      }
    }
  }

  .nameContainer {
    .timestamp {
      display: inline-block;
      float: right;
      font-size: 10px;
      margin-right: 5px;
      margin-top: 3px;
    }

    .molecule-icon {
      display: inline-block;
      vertical-align: middle;

      i {
        color: darkslategray;
        font-size: 18px;
        margin-left: 5px;
      }
    }

    .molecule-name {
      display: inline-block;
      height: 14px;
      margin-left: 5px;
      overflow: hidden;
      width: 157px;
    }
  }

  .eventContainer {
    background: lightgray;
    border-radius: 2px;
    color: #000000A8;
    font-weight: 700;
    height: 30px;
    margin: 4px;
    position: relative;
    text-align: center;
    width: 80%;

    i {
      font-size: 16px;
      vertical-align: sub;
    }

    div {
      display: inline-block;
      font-size: 10px;
      height: 19px;
      margin-top: 8px;
      max-width: 240px;
      overflow: hidden;

      text-overflow: ellipsis;
      vertical-align: text-bottom;
      white-space: nowrap;
    }

    &:after {
      border-bottom: 14px solid transparent;
      border-left: 21px solid #EFEFEF;
      border-top: 14px solid transparent;
      bottom: 0;
      content: '';
      height: 0;
      left: 0;
      position: absolute;
      width: 0;
    }

    &:before {
      border-bottom: 14px solid transparent;
      border-left: 21px solid lightgray;
      border-top: 14px solid transparent;
      bottom: 0;
      content: '';
      height: 30px;
      position: absolute;
      right: -20px;
      width: 0;
    }
  }

  .cardContainer {
    background-color: whitesmoke;
    border-radius: 15px;
    display: block;
    height: 100%;
    width: 250px;

    .receptor-data {
      display: grid;
      text-align: center;

      i {
        height: 20px;
        margin-bottom: 1px;
        margin-top: -2px;
      }
    }

    label {
      display: block;
      font-size: 12px;
      margin-bottom: 8px;
      margin-top: 4px;
      text-align: center;
    }

    &.rep-molecule-selected {
      border: 4px solid #EA5C2F;
    }
  }

  .linkMidpoint {
    ellipse {
      fill: white;
      stroke: black;
      stroke-width: 1;
    }

    text {
      fill: black;
      font-size: 10px;
      stroke: transparent;
      text-anchor: middle;
    }
  }
}

.guideline-vertical {
  border-left: 1px solid #EA5C2F !important;
  height: 4000px;
  top: -2000px !important;
  width: 1px !important;

  &.handle-w {
    left: -1px !important;
  }

  &.handle-e {
    right: -1px !important;
  }

  &.center-guideline.gridster-item-resizable-handler {
    border-color: #74C5FF !important;
    border-left: 1px solid #74C5FF !important;
    left: 50% !important;
  }
}

.guideline-corner {
  border: 4px solid #EA5C2F !important;
  border-radius: 10px;
}

.handle-se {
  &.guideline-corner {
    bottom: -4px !important;
    right: -4px !important;
  }
}

.handle-ne {
  &.guideline-corner {
    right: -4px !important;
    top: -4px !important;
  }
}

.handle-sw {
  &.guideline-corner {
    bottom: -4px !important;
    left: -4px !important;
  }
}

.handle-nw {
  &.guideline-corner {
    left: -4px !important;
    top: -4px !important;
  }
}

.guideline-horizontal {
  border-top: 1px solid #EA5C2F !important;
  height: 1px !important;
  left: -2000px !important;
  width: 4000px !important;

  &.handle-n {
    top: -1px !important;
  }

  &.handle-s {
    bottom: -1px !important;
  }

  &.center-guideline.gridster-item-resizable-handler {
    border-color: #74C5FF !important;
    border-top: 1px solid #74C5FF !important;
    top: 50% !important;
  }
}

.gridster-preview.guideline {
  background-color: rgba(234, 92, 47, 0.69) !important;
}

.molecule-center-guideline {
  border: 1px solid #74C5FF !important;
}

app-monitoring {
  .debug-session {
    background-color: aliceblue;
    border-top: 1px solid black;
    bottom: 0;
    max-height: 500px;
    overflow-y: auto;
    width: 100%;
    z-index: 9999999999;

    &.close-session {
      height: 20px !important;
    }

    .process {
      background-color: white;
      border: 2px solid black;
      float: left;
      margin: 10px;
      margin-top: 30px;
      padding: 5px;
    }

    .particles-list {
      list-style: none;
      margin: 0px;
      padding: 0px;

      li {
        border-top: 1px solid black;
      }

      .completed {
        background-color: darkseagreen;
      }
    }
  }
}

.running-loader-spinner-top {
  position: fixed;
  right: 10px;
  top: 10px;
  z-index: 9999999999;

  .mat-progress-spinner circle,
  .mat-spinner circle {
    stroke: #EA5C2F;
  }
}

.running-loader-spinner-bottom {
  bottom: 10px;
  position: fixed;
  right: 10px;
  z-index: 9999999999;

  .mat-progress-spinner circle,
  .mat-spinner circle {
    stroke: #EA5C2F;
  }
}

mat-option {
  font-size: 12px !important;
  height: 25px !important;
}

mat-stepper {
  background-color: transparent !important;

  &.stepper-scrollbars {
    .mat-horizontal-stepper-header-container {
      overflow: auto;
    }

    .mat-step-header,
    .mat-horizontal-stepper-header {
      overflow: initial !important;
    }

    &.mat-stepper-vertical {
      overflow: auto;
    }
  }

  .mat-horizontal-stepper-content {
    //height: 300px;
    //position: relative;
    bottom: 0;
    display: block;
    height: calc(100% - 97px);
    left: 0;
    position: absolute;
    width: 100%;
  }

  .workgroup-gridster {
    bottom: 0px;
    left: 0px;
    position: absolute;
  }
}

.no-events {
  pointer-events: none !important;
}

.user-guide-search {
  position: relative;
  width: 200px;

  .search-bar {
    width: 200px;
  }

  .search-icon {
    align-items: center;
    background-color: black;
    border-radius: 40px;
    cursor: pointer;
    display: flex;
    height: 28px;
    justify-content: center;
    width: 28px;

    .material-icons-round {
      color: white;
      vertical-align: middle;
    }

    &.search-open-style {
      bottom: 0;
      margin: auto 0;
      position: absolute;
      right: 5px;
      top: 0;

      .material-icons-round {
        font-size: 20px;
      }
    }

    &.search-close-style {
      .material-icons-round {
        font-size: 22px;
      }
    }
  }

  mat-form-field,
  .mat-form-field-wrapper {
    height: 30px !important;
  }

  mat-form-field {
    color: black;
    width: 200px;

    .mat-form-field-outline {
      background-color: white;
      border-radius: 2px;
      color: white;
    }
  }

  .mat-form-field-appearance-legacy .mat-form-field-wrapper {
    padding-bottom: 0.75em !important;
  }

  .mat-form-field-appearance-legacy .mat-form-field-underline {
    bottom: 0.25em !important;
  }

  .mat-form-field-appearance-legacy .mat-form-field-wrapper {
    padding-bottom: 0 !important;
  }

  .mat-form-field-infix {
    padding: 0.3em 0 !important;
  }

  .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
    color: white;
  }
}

.running-mode {
  app-workgroup-molecule {
    .fit {
      overflow-x: unset !important;
      overflow-y: unset !important;
    }
  }
}

gridster-item {
  overflow: unset !important;
}

.help-icon {
  background-color: white;
  border-radius: 40px;
  color: darkgrey;
  cursor: pointer !important;
  font-size: 21px;
  position: absolute;
  right: 5px;

  &:hover {
    color: gray;
  }
}

[disabled='true'],
.disabled {
  pointer-events: all !important;
}

$dark-bg-color: #111111;
$dark-semi-bg-color: #1A1A1A;
$dark-accent-color: #212529;
$dark-font-color: #D6D6D6;
$dark-font-gray-color: gray;
$dark-border-color: #353535;

html[data-theme='dark'] {
  //  filter: invert(1) hue-rotate(180deg) !important;

  .toggle-children {
    filter: invert(1) hue-rotate(180deg) !important;
  }

  .smart-template-container {
    filter: invert(1) hue-rotate(180deg) !important;

    img:not(.selection-type-icon) {
      filter: invert(1) hue-rotate(180deg) !important;
    }
  }

  .emulator-preview-options {
    .preview-light-btn {
      background-color: white !important;
      border: 1px solid black !important;

      i {
        color: black !important;
      }
    }

    .preview-dark-btn {
      background-color: black !important;
      border: 1px solid white !important;

      i {
        color: white !important;
      }
    }
  }

  .library-item-chip {
    &:hover {
      box-shadow: -4px 4px 11px -2px #232222 !important;
    }
  }

  mat-bottom-sheet-container {
    background-color: $dark-accent-color !important;
    border: 1px solid $dark-border-color !important;

    li {
      background-color: $dark-font-gray-color !important;
    }
  }

  .mat-checkbox-label {
    color: $dark-font-color !important;
  }

  .mat-checkbox-frame {
    border-color: $dark-font-gray-color !important;
  }

  .mat-calendar-controls {
    color: $dark-font-color !important;

    .mat-icon-button {
      color: $dark-font-color !important;
    }
  }

  .mat-calendar-body-today {
    border-color: $dark-font-color !important;
  }

  .mat-datepicker-content {
    background-color: $dark-accent-color !important;

    th {
      color: $dark-font-gray-color !important;
    }

    td {
      color: $dark-font-color !important;
    }

    .mat-calendar-body-cell-content {
      color: $dark-font-color !important;
    }
  }

  .mat-select-arrow {
    color: $dark-font-color !important;
  }

  // rep molecules
  gridster {
    app-image-molecule {
      .no-image {
        filter: invert(1) hue-rotate(180deg) !important;
      }
    }

    app-radio-molecule {
      mat-radio-group {
        background-color: $dark-accent-color !important;
      }

      .mat-radio-label-content {
        color: $dark-font-color !important;
      }
    }

    app-table-molecule {
      .table-wrapper {
        background-color: $dark-accent-color !important;
      }

      mat-form-field {
        background-color: $dark-accent-color !important;
        border-radius: 0px !important;
      }

      table {
        background-color: $dark-accent-color !important;
        border: 1px solid $dark-border-color !important;

        tr,
        th {
          background-color: $dark-semi-bg-color !important;
          color: $dark-font-color !important;
        }

        tr:nth-child(even) {
          background-color: $dark-bg-color !important;
        }
      }

      .table-pagination {
        background-color: $dark-accent-color !important;
      }
    }

    app-chart-molecule {
      svg {
        text {
          fill: $dark-font-color !important;
        }
      }
    }

    app-icon-molecule {
      button {
        background-color: $dark-accent-color !important;
      }

      .material-icons-round {
        color: $dark-font-color !important;
      }
    }

    app-button-molecule {
      button {
        background-color: $dark-accent-color !important;
        border: 1px solid $dark-border-color !important;
        box-shadow: none !important;
        color: $dark-font-color !important;
      }
    }

    app-dropdown-molecule {
      .mat-select-placeholder {
        color: $dark-font-gray-color !important;
      }

      mat-form-field {
        background-color: $dark-accent-color !important;
        border: 1px solid $dark-border-color !important;
      }
    }

    app-datepicker-molecule {
      ::placeholder {
        color: $dark-font-gray-color !important;
      }

      .mat-datepicker-toggle-default-icon {
        color: $dark-font-gray-color !important;
      }

      textarea {
        color: $dark-font-color !important;
      }

      mat-form-field {
        background-color: $dark-accent-color !important;
        border: 1px solid $dark-border-color !important;
      }

      input {
        background-color: $dark-accent-color !important;
      }
    }

    app-textarea-molecule {
      ::placeholder {
        color: $dark-font-gray-color !important;
      }

      textarea {
        background-color: $dark-accent-color !important;
        border: 1px solid $dark-border-color !important;
        color: $dark-font-color !important;
      }
    }

    app-textbox-molecule {
      ::placeholder {
        color: $dark-font-gray-color !important;
      }

      textarea {
        color: $dark-font-color !important;
      }

      mat-form-field {
        background-color: $dark-accent-color !important;
        border: 1px solid $dark-border-color !important;
      }
    }

    app-label-molecule {
      .textFitted,
      .textfit {
        background: transparent !important;
        color: $dark-font-color !important;
      }
    }

    app-h1-molecule,
    app-h2-molecule,
    app-h3-molecule,
    app-h4-molecule,
    app-h5-molecule {
      h1,
      h2,
      h3,
      h4,
      h5 {
        color: $dark-font-gray-color !important;
      }

      app-workgroup-molecule {
        .workgroup-gridster {
          //background-color: $dark-semi-bg-color !important;
          border: 1px solid $dark-border-color !important;
        }
      }
    }

    //

    app-admin {
      .mat-table,
      .mat-paginator {
        filter: invert(1) hue-rotate(180deg) !important;
      }
    }

    .context-actions {
      filter: invert(1) hue-rotate(180deg) !important;
    }

    app-hierarchy-shared {
      mat-tree {
        filter: invert(1) hue-rotate(180deg) !important;
      }
    }

    .mat-snack-bar-container {
      background-color: $dark-accent-color !important;
      border: 1px solid $dark-border-color !important;
    }

    iframe:not(.emulator-iframe) {
      filter: invert(1) hue-rotate(180deg) !important;
    }

    .cobbler-responsive-loading-container {
      filter: invert(1) hue-rotate(180deg) !important;
    }

    hr {
      background-color: $dark-font-gray-color !important;
    }

    tree-node-content {
      color: $dark-font-color !important;
    }

    .mat-expansion-panel-header {
      .mat-expansion-panel-header-title {
        color: $dark-font-gray-color !important;
      }

      .mat-expansion-panel-header-description {
        color: $dark-font-gray-color !important;
      }

      .mat-expansion-indicator::after {
        color: $dark-font-gray-color !important;
      }
    }

    button.mat-stroked-button {
      border: 1px solid $dark-border-color !important;
    }

    .layouts-container {
      background-color: $dark-semi-bg-color !important;
      color: $dark-font-color !important;
    }

    #footer {
      background-color: $dark-bg-color !important;
    }

    .dropdown-content {
      background-color: $dark-bg-color !important;

      .section {
        color: $dark-font-color !important;
      }
    }

    .dropdown-content button,
    .dropdown-content a {
      color: $dark-font-color !important;
    }

    .mat-select-value-text {
      color: $dark-font-gray-color !important;
    }

    .mat-select-panel,
    .mat-autocomplete-panel {
      background-color: $dark-semi-bg-color !important;
      border: 1px solid $dark-border-color;

      .mat-option-text {
        color: $dark-font-gray-color !important;
      }
    }

    .draggable-window-container {
      background-color: $dark-semi-bg-color !important;
      color: $dark-font-gray-color !important;
    }

    .node-wrapper:hover {
      background-color: $dark-accent-color !important;
      color: $dark-font-gray-color !important;

      .view-actions {
        background-color: $dark-accent-color !important;
      }
    }

    .selected-element-node {
      background-color: $dark-accent-color !important;
    }

    .view-actions {
      background-color: $dark-semi-bg-color !important;
    }

    .workbook-view {
      color: $dark-font-color !important;
      filter: invert(1) hue-rotate(180deg) !important;
    }

    .cobbler-loading {
      filter: invert(1) hue-rotate(180deg) !important;
    }

    *::-webkit-scrollbar-corner {
      background-color: black !important;
    }

    *::-webkit-scrollbar-thumb {
      background-color: $dark-semi-bg-color !important;
    }

    *::-webkit-scrollbar-thumb:hover {
      background-color: $dark-accent-color !important;
    }

    *::-webkit-scrollbar-track {
      background-color: $dark-bg-color !important;
    }

    [disabled='true'] {
      color: $dark-font-gray-color !important;
    }

    mat-dialog-container,
    .mat-card {
      background-color: $dark-semi-bg-color !important;
      color: $dark-font-color !important;
    }

    #leftSplitArea,
    #rightSplitArea {
      background-color: $dark-semi-bg-color !important;
      color: $dark-font-color !important;

      .libray-title-section {
        color: $dark-font-color !important;
      }

      .library-item-chip {
        background-color: $dark-accent-color !important;
        color: $dark-font-color !important;
      }

      img {
        filter: invert(1) hue-rotate(180deg) !important;
      }
    }

    input {
      color: $dark-font-color !important;
    }

    .rename-event-input {
      color: $dark-bg-color !important;
    }

    .mat-tab-label {
      background-color: $dark-semi-bg-color !important;
      color: $dark-font-color !important;
    }

    .mat-form-field-appearance-legacy .mat-form-field-label {
      color: $dark-font-color !important;
    }

    .mat-form-field-appearance-legacy .mat-form-field-underline {
      background-color: $dark-font-color !important;
    }

    mat-expansion-panel {
      background-color: $dark-semi-bg-color !important;
      color: $dark-font-color !important;
    }

    mat-sidenav-content {
      background-color: $dark-semi-bg-color !important;
      border: 1px solid $dark-border-color;
      color: $dark-font-color !important;
    }

    .wrapper {
      //background-color: $dark-semi-bg-color !important;
    }

    #grid-main-container,
    .work-area-wrapper {
      background-color: $dark-semi-bg-color !important;

      .work-area-wrapper {
        &.develop {
          background-image: radial-gradient($dark-accent-color 20%, #FFFFFF00 20%) !important;
        }
      }
    }

    .new-cobble-container .container {
      background-color: $dark-semi-bg-color !important;
      color: $dark-font-color !important;
    }

    body {
      background-color: $dark-semi-bg-color !important;
    }

    .sheet-area,
    .as-split-gutter {
      background-color: $dark-semi-bg-color !important;
      color: $dark-font-color !important;
    }

    .as-split-gutter {
      background-color: $dark-accent-color !important;
      color: $dark-font-color !important;
    }

    .cobble-info {
      background-color: $dark-bg-color !important;
      color: $dark-font-color !important;
    }

    .draggable-window-container {
      border: 1px solid $dark-border-color;
    }

    .mat-dialog-container {
      border: 1px solid $dark-border-color;

      .mat-card {
        border: 1px solid $dark-border-color;
      }
    }

    .mat-expansion-panel {
      border: 1px solid $dark-border-color;
    }

    .new-cobble-container {
      .container {
        border: 1px solid $dark-border-color;
      }
    }

    cbb-dashboard {
      #header {
        background-color: $dark-bg-color !important;
      }

      .cobble-card {
        background-color: $dark-bg-color !important;
        box-shadow: 0 1px 5px 0 rgb(88 88 89 / 21%) !important;
        color: $dark-font-color !important;

        img {
          filter: invert(1) hue-rotate(180deg) !important;
        }

        .cobble-options {
          background-color: $dark-semi-bg-color !important;
        }
      }

      .cobbles {
      }

      .no-cobbles {
        color: $dark-font-color !important;
      }

      .company-name,
      .section-header {
        background-color: $dark-accent-color !important;
        color: $dark-font-color !important;
      }
    }

    .is-loading {
      color: transparent !important;

      .image,
      .node-content-wrapper,
      .mat-tab-label-content,
      .mat-form-field,
      button,
      h2,
      p,
      mat-chip {
        background: linear-gradient(120deg, #535353 8%, #B8B8B8 18%, #535353 33%);
        color: transparent !important;
      }
    }

    .ds-highlighted {
      color: $dark-semi-bg-color !important;
    }
  }
}

.highlight-event-transaction {
  border: 3px solid #EA5C2F;
}

.mat-optgroup-label {
  height: auto !important;
}

.particle-icon {
  font-size: 16px;
  vertical-align: sub;
}

.focus-window {
  z-index: 999 !important;
}

.receive-event-debug {
  border: 2px solid greenyellow;
  border-radius: 4px;
}

.run-molecule-debug {
  border: 2px solid indianred;
  border-radius: 4px;
}

.fire-event-debug {
  border: 2px solid deepskyblue;
  border-radius: 4px;
}

app-h1-molecule .wrapper-element,
app-h2-molecule .wrapper-element,
app-h3-molecule .wrapper-element,
app-h4-molecule .wrapper-element,
app-h5-molecule .wrapper-element,
app-label-molecule .wrapper-element,
app-progress-molecule .wrapper-element {
  white-space: nowrap;

  h1,
  h2,
  h3,
  h4,
  h5,
  p,
  div {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &.wrap-text {
    h1,
    h2,
    h3,
    h4,
    h5,
    p {
      overflow: unset !important;
      white-space: normal !important;
    }

    div {
      white-space: normal !important;
    }
  }
}

.tab-info {
  background-color: white;
  border-radius: 10px;
  color: #EA5C2F;
  font-size: 20px;
  position: absolute;
  right: 0;
  z-index: 1;
}

.setting-tab-order-molecule {
  gridster-item {
    border: 2px solid #EA5C2F96;
    border-radius: 4px;
  }
}

.overlay-backdrop-hidden {
  height: 0px !important;
  pointer-events: inherit !important;
  z-index: -1;

  .cdk-overlay-backdrop {
    bottom: inherit;
    display: none !important;
    left: inherit;
    pointer-events: inherit;
    position: relative;
    right: inherit;
    top: inherit;
  }
}

.center-rep-molecule {
  left: 50% !important;
  position: fixed !important;
  top: 50% !important;
  -webkit-transform: translate(-50%, -50%) !important;
  transform: translate(-50%, -50%) !important;
}

.name-container {
  display: block;
}

.search-particle-field {
  .mat-form-field-wrapper {
    padding-bottom: 5px !important;
  }
}

.compact-menu {
  .mat-menu-content {
    hr {
      margin: 0px !important;
    }

    .mat-menu-content button {
      height: 35px !important;
    }

    .mat-menu-item {
      line-height: 35px !important;

      .mat-icon {
        font-size: 20px;
        margin-top: 3px;
      }
    }

    button {
      height: 35px !important;
    }
  }
}

.modern-ui {
  font-family: $system-font !important;

  //$system-font: "Roboto", sans-serif;

  $system-font: 'Helvetica Neue', sans-serif;
  letter-spacing: 0.08em !important;

  .material-icons-round,
  .material-icons {
    font-family: 'Material Symbols Outlined' !important;
  }

  .material-icons-sharp {
    font-family: 'Material Icons Sharp' !important;
  }

  $custom-typography: mat.define-typography-config(
          $font-family: $system-font,
  );
  $custom-typography: mat.define-typography-config(
          $font-family: $system-font,
  );
  @include mat.core($custom-typography);

  $border-radius: 2px !important;

  .layouts-container {
    border-radius: $border-radius;

    #header {
      border-radius: 2px 2px 0px 0px !important;
    }

    #footer {
      border-radius: 0px 0px 2px 2px !important;
    }
  }

  .mat-expansion-panel:not(.debug-data) {
    border-radius: $border-radius;
  }

  .mat-menu-panel {
    border-radius: $border-radius;
  }

  .drop-placeholder {
    border-radius: $border-radius;
  }

  app-themes-panel {
    .themes-container {
      ul {
        li {
          border-radius: $border-radius;
        }
      }
    }
  }

  #rightSplitArea {
    .library-container {
      ul {
        display: grid;
        grid-gap: 5px;
        grid-template-columns: repeat(auto-fill, minmax(68px, 1fr));

        li {
          display: inline-block;
        }
      }
    }
  }

  button.leap-button {
    border-radius: 2px;
  }

  cbb-dashboard {
    .cobble-card {
      border-radius: $border-radius;

      .cobble-options {
        border-radius: $border-radius;
      }
    }
  }

  draggable-window {
    .draggable-window-container {
      border-radius: $border-radius;
    }
  }

  app-process-properties {
    .bus {
      border-radius: $border-radius;
    }
  }

  molecule-settings {
    .panel-viewer-wrapper {
      border-radius: $border-radius;
    }
  }

  app-data-element-particle {
    .data-element-container {
      border-radius: $border-radius;
    }
  }

  app-molecules-context-menu {
    .option-btn {
      border-radius: $border-radius;
    }
  }

  app-new-cobble {
    .container {
      border-radius: $border-radius;
    }
  }

  app-dynamic-select {
    li {
      border-radius: $border-radius;
    }
  }

  mat-dialog-container,
  mat-card {
    border-radius: $border-radius;
  }

  .dropdown-content {
    border-radius: $border-radius;

    a:hover {
      border-radius: $border-radius;
    }
  }

  .ds-highlighted {
    border-radius: $border-radius;
  }

  .node-wrapper:hover {
    border-radius: $border-radius;
  }

  .node-content-wrapper::before {
    border-bottom: none !important;
    border-left: none !important;
  }

  .node-content-wrapper {
    color: #504F4F !important;
    padding: 5px !important;

    .element-icon {
      margin-right: 7px;
    }
  }

  .name-container {
    display: block;
    padding: 3px 8px !important;
  }

  .selected-element-node {
    border-radius: 2px;
  }

  .events-drag {
    //color: #504F4F !important;
  }

  .toggle-children {
    opacity: 0.5;
    top: 9px;
  }

  .event-section,
  #dsTreeContainer,
  #cobblesTree,
  .datasource-section-body {
    .events-drag,
    .node-content-wrapper[draggable='true'] {
      & .name:not(.name-ds-open, .ds-highlighted):hover {
        border-radius: $border-radius;
        box-shadow: -1px 2px 3px 0px #B3B3B357;
        padding: 1px 2px !important;
      }
    }
  }


  #dsTreeContainer {
    tree-viewport {
      overflow-x: hidden;
      overflow-y: auto;
      width: 100%;

      .node-wrapper {
        min-width: fit-content;

        tree-node-content {
          display: flex;
          justify-content: space-between;
        }
      }
    }
  }
}

.share-tree {


  .share-node-title {
    align-items: center;
    display: flex;

    mat-icon {
      color: #EA5C2F;
      margin-right: 5px;
    }
  }

  tree-node-checkbox {
    align-self: center;
    height: 14px;
    width: 14px;
  }

  tree-node-expander {
    align-self: center;

    .toggle-children-wrapper {
      display: contents;
      height: 14px;
      padding: 0px;
      width: 14px;

      .toggle-children {
        margin-left: 5px;
        top: 0px;
      }
    }
  }
}

#apiTree {
  .hide-node {
    display: none;
  }

  tree-node-wrapper {
    tree-node-checkbox {
      display: none;

      input {
        accent-color: #EA5C2F;
      }
    }

    &:hover {
      tree-node-checkbox {
        display: block;
      }
    }
  }

  tree-node-children {
    tree-node-checkbox {
      display: none !important;
    }
  }
}

#select-nodes-tree {
  tree-node-checkbox {
    input {
      accent-color: #EA5C2F;
    }
  }
}

#select-nodes-tree {

  tree-node-wrapper {
    tree-node-checkbox {
      display: none !important;
    }
  }

  tree-node-children {
    tree-node-wrapper {
      tree-node-checkbox {
        display: block !important;
      }
    }
  }

  .node-wrapper {
    padding: 0 10px !important;

    tree-node-content {
      text-wrap: nowrap;
    }
  }
}

.context-menu {
  .menu {
    a {
      .mat-checkbox-layout {
        margin: 0 10px 0 0;
      }
    }
  }
}

.custom-dialog-container .mat-dialog-container {
  overflow-y: hidden;
  padding: 0;
}

.mat-step-icon-state-done {
  background-color: forestgreen !important;
}

.animated-transform {
  transition-duration: 150ms;
  transition-property: transform;
}

.gridster-preview {
  display: none !important;

  &.moving {
    display: block !important;
  }
}

app-representative-properties {
  .mat-form-field-flex {
    border: 1px solid #8080802E;
    border-radius: 2px;
    padding: 2px;

    .mat-form-field-infix {
      background-color: #80808017;
      padding: 0px 2px !important;
    }

    .mat-form-field-label {
      top: 9px !important;
    }
  }

  .mat-form-field-underline {
    display: none;
  }

  .mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
  .modern-ui
  .mat-form-field-appearance-legacy.mat-form-field-can-float
  .mat-input-server:focus
  + .mat-form-field-label-wrapper
  .mat-form-field-label {
    transform: translateY(-10px) scale(0.75) perspective(100px) translateZ(0.00106px) !important;
  }
}

.grid {
  margin-top: 50px;
}

.work-area-content.fixed-context-menu {
  .elements-context-menu-container {
    top: 50px;
  }
}

.top-toolbar.compact {
  .compact-top-bar {
    height: 40px !important;
    width: 100% !important;
  }

  .grid {
    margin-top: 40px;

    &.running {
      margin-top: 0px !important;
    }
  }

  .work-area-content.fixed-context-menu {
    .elements-context-menu-container {
      top: 40px !important;
    }
  }
}

.left-side-toolbar {
  .grid {
    margin-top: 0px;
    width: calc(100% - 40px) !important;
  }

  .work-area-content.fixed-context-menu {
    .elements-context-menu-container {
      top: 0px !important;
    }
  }

  .compact-top-bar {
    flex-direction: column;
    height: 100% !important;
    position: absolute !important;
    width: 40px !important;

    .user-info {
      height: 15px !important;
      width: 40px !important;
    }

    .top-logo {
      height: 14px !important;
      margin-left: 0 !important;
      margin-right: 0 !important;
      margin-top: 16px !important;
      width: 36px !important;
    }

    .divider {
      border-top: 1px solid white !important;
      height: 1px !important;
      margin: 10px 0px !important;
      width: 100% !important;
    }

    .separator {
      border-top: 1px solid white !important;
      height: 1px !important;
      margin: 10px 0px !important;
      width: 60% !important;
    }

    .spacer {
      height: 10px !important;
      width: 100% !important;
    }
  }

  .left-section,
  .center-section,
  .right-section {
    flex-direction: column;
  }

  .grid {
    margin-left: 40px;
  }

  .work-area-content.fixed-context-menu {
    .elements-context-menu-container {
      //left: 40px !important;
    }
  }
}

.right-side-toolbar {
  .compact-top-bar {
    flex-direction: column;
    height: 100% !important;
    position: absolute !important;
    width: 40px !important;
  }

  .grid {
    margin-right: 40px;
  }

  .work-area-content.fixed-context-menu {
    .elements-context-menu-container {
      right: 40px !important;
    }
  }
}

.wrapper-element {
  mat-form-field.mat-form-field-appearance-fill {
    .mat-form-field-underline::before {
      background-color: transparent !important;
    }

    .mat-form-field-underline {
      bottom: 5px !important;
    }

    .mat-form-field-flex {
      background-color: transparent !important;
    }
  }
}

.mat-progress-spinnerr:not(.spinner-molecule) circle,
.mat-spinner:not(.spinner-molecule) circle {
  stroke: #EA5C2F !important;
}

#NativeLogin {
  .mat-form-field-outline {
    background: #F4F2F2 !important;
    opacity: 1 !important;
  }
}

hotkeys-cheatsheet {
  //height: 700px;
  //left: 50%;
  //margin: 0;
  //position: absolute;
  //top: 50%;
  //transform: translate(-50%, -50%);
  //width: 700px;
  //z-index: 9999999;

  .cfp-hotkeys-container {
    background-color: #FBFBFB !important;
    border: 2px solid #E6E6E6;
    border-radius: 4px;

    .cfp-hotkeys {
      table {
        //display: inline-block;
        //height: 609px;
        //left: 50%;
        //margin: 0;
        //overflow: auto;
        //position: relative;
        //top: 47%;
        //transform: translate(-50%, -50%);
      }
    }
  }
}

.transition-animation {
  transition: all 100ms !important;
}

.tree-separator {
  border-top: 2px solid lightgray;
  position: absolute;
  top: -2px;
  width: calc(100% - 5px);
}

#cobblesTreeContainer {
  .node-content-wrapper {
    position: relative;
  }
}

.available-datasources-icon-position {
  .mat-option-text {
    align-items: center !important;;
    display: flex !important;
    gap: 5px !important;;
  }
}

.suggestions-group-message {
  .group-message {
    display: flex;
    gap: 5px;

    i {
      color: #808080C9;
      padding: 4px 0;
    }

    p {
      color: #808080C9;
      font-size: 12px;
      margin-bottom: 0;
    }
  }

  &.mat-autocomplete-panel.mat-autocomplete-hidden {
    visibility: visible !important;
  }
}

.side-panel-section-header-height {
  height: 24px;
}

// disabling trees section ellipsis separator
.section-data-indicator {
  color: transparent !important;
  cursor: pointer;
  height: 5px;
}

.theme-search, mat-form-field {

  .modern-ui .mat-form-field-wrapper, .mat-form-field-wrapper {
    padding-bottom: 0;
  }

  .mat-form-field-appearance-outline .mat-form-field-infix, .mat-form-field-infix {
    padding-bottom: 8px !important;
    padding-top: 7px !important;
  }

  .mat-form-field-label-wrapper {
    top: -14px !important;
  }

  .mat-form-field-outline-start {
    border-radius: 2px 0 0 2px !important;
  }

  .mat-form-field-outline-end {
    border-radius: 0 2px 2px 0 !important;
  }

  input {
    max-width: fit-content;
  }

  &.mat-form-field-appearance-outline.mat-focused .mat-form-field-label {
    color: #EA5C2F !important;
  }

  &.mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
    color: #EA5C2F;
  }

  &.mat-form-field-appearance-outline .mat-form-field-flex {
    margin-top: -1.5px !important;
  }
}

.theme-configuration-container {
  .modern-ui .mat-form-field-wrapper, .mat-form-field-wrapper, .mat-form-field-wrapper {
    padding-bottom: 0;
  }
}

.last-used-element {
  color: #EA5C2F;
}

// dev tools

.bus-particle-breakpoint-pause {
  background-color: #EA5C2F;
  border-radius: 2px;
  padding: 5px;

  .bus-molecule-container {
    background-color: white;
    border-radius: 2px;
  }
}

.scrollbar-gutter {
  scrollbar-gutter: stable;
}

.molecule-configuration-field {
  width: 100%;

  .mat-form-field-wrapper {
    padding-bottom: 0 !important;
  }


}

//

#grid-main-container.grid {
  max-width: 100%;
  width: 100%;
}

app-generation-app-wizard {
  .mat-form-field-wrapper, .mat-form-field-wrapper, .mat-form-field-wrapper {
    padding-bottom: 0 !important;
  }
}
